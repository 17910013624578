import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, TabsAlignment } from 'wix-ui-tpa';
import { connect } from '../../../../common/components/runtime-context';
import withTranslate from '../../../../common/hoc/with-translate';
import { getActiveTab } from '../../../store/active-tab/active-tab-selectors';
import {
  MY_POSTS_PAGE_TAB_DRAFTS,
  MY_POSTS_PAGE_TAB_PUBLICATIONS,
  // MY_POSTS_PAGE_TAB_SUBMISSIONS,
} from '../../../constants/my-posts-page';
import { classes } from './page-tabs.st.css';

export const ALIGNMENT = TabsAlignment;

const TAB_BY_TAB_INDEX = [
  MY_POSTS_PAGE_TAB_PUBLICATIONS,
  // MY_POSTS_PAGE_TAB_SUBMISSIONS,
  MY_POSTS_PAGE_TAB_DRAFTS,
];

class PageTabs extends React.Component {
  handleTabClick = async (tabIndex) => {
    const tab = TAB_BY_TAB_INDEX[tabIndex];
    if (tab) {
      await this.props.setActiveTab(tab);
      await this.props.fetchPageData();
    }
  };

  render() {
    const { t, activeTab, alignment } = this.props;
    return (
      <Tabs
        data-hook="my-posts-page-tabs"
        className={classes.root}
        items={[
          { title: t('tabs.publications') },
          // { title: t('tabs.submissions') },
          { title: t('tabs.drafts') },
        ]}
        activeTabIndex={TAB_BY_TAB_INDEX.indexOf(activeTab)}
        onTabClick={this.handleTabClick}
        alignment={alignment}
      />
    );
  }
}

PageTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  fetchPageData: PropTypes.func.isRequired,
  alignment: PropTypes.string,
};

PageTabs.defaultProps = {
  alignment: TabsAlignment.left,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  activeTab: getActiveTab(state),
  setActiveTab: actions.setActiveTabPromisified,
  fetchPageData: actions.fetchMyPostsPageDataPromisified,
});

export default flowRight(withTranslate, connect(mapRuntimeToProps))(PageTabs);
